<template>
  <va-item
    clickable
    @click="clicked"
  >
    <template v-if="hasAvatar || !!icon">
      <va-item-section
        avatar
        v-if="hasAvatar"
      >
        <slot name="avatar" />
      </va-item-section>
      <va-icon
        :name="icon"
        v-else
      />
    </template>

    <va-item-section>
      <va-item-label caption>
        <slot name="title">
          <strong class="list--title">{{ title }}</strong>
        </slot>
      </va-item-label>

      <va-item-label>
        <slot>{{ label }}</slot>
      </va-item-label>
    </va-item-section>

    <template v-if="hasSide">
      <va-item-section side>
        <slot name="side"></slot>
      </va-item-section>
    </template>
  </va-item>
</template>

<script>
export default {
  name: 'text-list',
  props: {
    to: {
      type: Object,
      required: false,
    },
    condition: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    hasAvatar: {
      type: Boolean,
      default: false,
    },
    hasSide: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clicked (event) {
      if (this.condition && !!this.to) {
        return this.$router.push(this.to)
      }
      this.$emit('click')
    },
  },
}
</script>

<style scoped>
.list--title {
  color: var(--brand-black);
  white-space: normal;
}
</style>
